
import axios from 'axios'
import { Toast } from 'vant'
import { setLocal } from '@/common/js/utils'
import router from '../router'

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/api' : '/'
axios.defaults.withCredentials = true
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['Authorization'] = localStorage.getItem('token') || ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
  const code = res.status;
  // console.log(res);
  if (code != 200) {
    Toast(res.data.msg)
    return false;
  }
  // if (code == 200&&res.data.code==1) {
  //   Toast(res.data.msg);
  // }
  if (code == 200&&res.data.code==402) {
    Toast("验证过期，重新登录");
    router.push({ path:'/login'})
    // return false;
  }
  if (code == 200&&res.data.code==401) {
    Toast("身份验证失败");
    router.push({ path:'/login'})
    // return false;
  }
  return res.data;
}, (error) => {
  //console.log('网络异常');
  Toast(error.response.data.message);
  return Promise.reject(error);
});
export function fetchPost(url, params) {
  const token=localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios.post(url, null,{headers:{
        Authorization:token
      },
      params:params
    }).then(response => {
      // console.log(response);
      resolve(response);
    }, err => {
      reject(err);
    }).catch((error) => {
      reject(error)
    })


  })
}

export default {axios,fetchPost}
